<template>
  <div>
    <!-- scroll-target="#scrolling-techniques-7" -->
    <v-app-bar
      absolute
      :dense="$vuetify.breakpoint.mobile"
      color="blue lighten-5"
      class="ma-0"
    >
      <v-icon>mdi-api</v-icon>
      <v-toolbar-title class="ml-3" v-if="!$vuetify.breakpoint.mobile"
        >Acesso API</v-toolbar-title
      >

      <v-spacer></v-spacer>
      <v-text-field
        v-if="mode === 'viewer'"
        hide-details
        dense
        filled
        class="text-field-transparent"
        v-model="search"
        persistent-placeholder
        placeholder="Digite as informações de pesquisa"
        light
        prepend-inner-icon="mdi-magnify"
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn
        v-if="mode === 'viewer'"
        elevation="2"
        :small="$vuetify.breakpoint.mobile"
        color="primary"
        :icon="$vuetify.breakpoint.mobile"
        @click="loadAccessCNPJ( 'insert')"
        class="pa-2"
        v-tooltip.bottom-end="'Novo registro no cadastro de API'"
          
      >
        {{ msgButton("Novo") }} <v-icon>mdi-plus</v-icon>
      </v-btn>

      <v-btn
        v-if="mode === 'viewer'"
        elevation="2"
        :icon="$vuetify.breakpoint.mobile"
        :small="$vuetify.breakpoint.mobile"
        color="secondary"
        class="pa-2 ml-2"
        @click="setMenuMobile"
        v-tooltip.bottom-end="'Menu geral do sistema'"
      >
        {{ msgButton("Menu") }}
        <v-icon v-if="!$store.state.isMenuVisibleMobile">mdi-menu-down </v-icon>
        <v-icon v-else> mdi-menu </v-icon>
      </v-btn>

      <!-- <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="mode === 'viewer'"
            elevation="2"
            :icon="$vuetify.breakpoint.mobile"
            :small="$vuetify.breakpoint.mobile"
            color="secondary"
            class="pa-2 ml-2"
            v-bind="attrs"
            v-on="on"
            @click="setMenuMobile"
          >
            {{ msgButton("Menu") }}
            <v-icon v-if="!$store.state.isMenuVisibleMobile"
              >mdi-menu-down
            </v-icon>
            <v-icon v-else> mdi-menu </v-icon>
          </v-btn>
        </template>
        <span>Menu principal</span>
      </v-tooltip> -->

      <!-- <v-btn
        v-if="mode === 'viewer'"
        elevation="2"
        :icon="$vuetify.breakpoint.mobile"
        :small="$vuetify.breakpoint.mobile"
        color="secondary"
        class="pa-2 ml-2"
        @click="setMenuMobile"
      >
        {{ msgButton("Menu") }}
        <v-icon v-if="!$store.state.isMenuVisibleMobile">mdi-menu-down </v-icon>
        <v-icon v-else> mdi-menu </v-icon>
      </v-btn> -->

      <v-btn
        v-if="mode === 'save' || mode === 'insert'"
        elevation="2"
        :small="$vuetify.breakpoint.mobile"
        color="primary"
        :icon="$vuetify.breakpoint.mobile"
        @click="save"
        class="pa-2"
        :disabled="!valid"
      >
        {{ msgButton("Salvar") }} <v-icon>mdi-check</v-icon>
      </v-btn>
      <v-btn
        v-if="mode === 'remove'"
        elevation="2"
        :small="$vuetify.breakpoint.mobile"
        color="error"
        :icon="$vuetify.breakpoint.mobile"
        @click="remove"
        class="pa-2"
      >
        {{ msgButton("Excluir") }} <v-icon>mdi-delete</v-icon>
      </v-btn>

      <v-btn
        v-if="mode === 'save' || mode === 'insert' || mode === 'remove'"
        elevation="2"
        :small="$vuetify.breakpoint.mobile"
        :icon="$vuetify.breakpoint.mobile"
        @click="reset"
        class="ml-2"
      >
        {{ msgButton("Cancelar") }} <v-icon>mdi-cancel</v-icon>
      </v-btn>
    </v-app-bar>
    <!-- id="scrolling-techniques-7" -->
    <v-sheet class="overflow-y-auto mt-6" max-height="1200"  >
      <v-container
        id="containerEdicao"
        fluid
        class="admin-pages"
        :style="heightScroll"
      >
        <!-- <v-app-bar
      
      dense
    >
      <v-app-bar-nav-icon @click="drawer = true">"mdi-api"</v-app-bar-nav-icon>

      <v-toolbar-title>Title</v-toolbar-title>
    </v-app-bar> -->

        <!-- <v-row>
      <v-col cols="12" md="3"  sm="1"  >
        <v-toolbar>
        <v-toolbar-title
          ><v-icon>"mdi-api"</v-icon> Cadastro API</v-toolbar-title
        >

        </v-toolbar>
      </v-col>
      <v-col cols="12" md="9" sm="3">
        <v-toolbar>
        <v-toolbar-title
          ><v-icon>"mdi-api"</v-icon> Pesquisar</v-toolbar-title
        >

        </v-toolbar>

      </v-col>
      

    </v-row>
 -->
        <!-- <v-toolbar-title><v-icon>"mdi-api"</v-icon> Cadastro API</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon>mdi-heart</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn> -->

        <div class="api-admin">
          <v-form
            ref="form"
            v-if="mode !== 'viewer'"
            class="mt-4 mt-lg-8"
            v-model="valid"
            lazy-validation
          >
            <v-row v-if="accessCNPJ.createdAt">
              <v-col cols="12" md="12" sm="12">
                <v-text-field
                  v-model="accessCNPJ.id"
                  label="ID"
                  outlined
                  readonly
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="4" sm="12">
                <v-text-field
                  v-model="accessCNPJ.name"
                  label="Nome da empresa"
                  outlined
                  hide-details="auto"
                  :readonly="mode === 'remove'"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" sm="12">
                <v-text-field
                  v-model="accessCNPJ.cnpj"
                  label="CNPJ do API"
                  required
                  outlined
                  hide-details="auto"
                  :rules="rulesCNPJ"
                  :readonly="accessCNPJ.id > 0"
                    
                 

                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" sm="12">
                <v-text-field
                  v-model="accessCNPJ.processadorid"
                  label="Processador ID da API"
                  required
                  
                  hide-details="auto"
                  :readonly="mode === 'remove'"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4" sm="12" v-if="accessCNPJ.createdAt">
                <v-text-field
                  :value="change(accessCNPJ.createdAt)"
                  label="Data Criação"
                  outlined
                  :readonly="mode === 'remove' || mode === 'save'"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" sm="12" v-if="accessCNPJ.accessAt">
                <v-text-field
                  :value="change(accessCNPJ.accessAt)"
                  label="Data Acesso"
                  outlined
                  hide-details="auto"
                  :readonly="mode === 'remove' || mode === 'save'"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" sm="12" v-if="accessCNPJ.modifiedAt">
                <v-text-field
                  :value="change(accessCNPJ.modifiedAt)"
                  label="Data Modificação"
                  :readonly="mode === 'remove' || mode === 'save'"
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" sm="12" v-if="accessCNPJ.modifiedAt">
                <v-text-field
                  :value="accessCNPJ.userId"
                  label="Usuário"
                  outlined
                  :readonly="mode === 'remove' || mode === 'save'"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="12" v-if="accessCNPJ.modifiedAt">
                <v-text-field
                  :value="accessCNPJ.nameUser"
                  label="Nome Usuário"
                  outlined
                  hide-details="auto"
                  :readonly="mode === 'remove' || mode === 'save'"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col cols="12" md="4" sm="12">
                <v-btn
                  color="primary"
                  v-if="mode === 'save' || mode === 'insert'"
                  @click="save"
                >
                  Salvar
                </v-btn>
                <v-btn color="error" v-if="mode === 'remove'" @click="remove">
                  Excluir
                </v-btn>
                <v-btn class="ml-2" @click="reset"> Cancelar </v-btn>
              </v-col>
            </v-row> -->
            
          </v-form>

          <div v-if="mode === 'viewer'">
            <hr class="ma-5" />

            <v-data-table
              dense           
              :headers="headers"
              :items="accessCNPJs"
              :search="search"
              item-key="cnpj"
              :items-per-page="15"
              class="elevation-1 pt-3"
              :footer-props="{
                'items-per-page-text': 'CNPJs por página',
                pageText: '{0}-{1} de {2}',
              }"
            >
              <template
                v-for="header in headers.filter((header) =>
                  header.hasOwnProperty('formatter')
                )"
                v-slot:[`item.${header.value}`]="{ header, value }"
              >
                {{ header.formatter(value) }}
              </template>
              <template v-slot:item.action="{ item }">
                <v-btn
                  elevation="2"
                  icon
                  @click="loadAccessCNPJ(item)"
                  class="mr-2"
                 v-tooltip.bottom-end="'Editar registro da tabela'" 

                  ><v-icon>mdi-pencil</v-icon></v-btn
                >
                <v-btn
                  elevation="2"
                  icon
                  @click="loadAccessCNPJ(item, 'remove')"
                  class=""
                  v-tooltip.bottom-end="'Remove registro da tabela'" 
                  ><v-icon>mdi-delete</v-icon></v-btn
                >
              </template>
            </v-data-table>
          </div>
        </div>
      </v-container>
    </v-sheet>

  </div>
  
</template>


<script>
// import PageTitle from "@/layouts/PageTitle.vue";
import { baseApiUrl, showError, showSucess } from "@/global";
import { mapState } from "vuex";
import axios from "axios";



export default {
  name: "AdminAcess",



 beforeMount() {
    window.addEventListener("beforeunload", this.preventNav)
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    })
  },

  beforeRouteLeave(to, from, next) {
    
    
    if ((this.mode === 'save') || (this.mode === 'insert')) {
      if (!window.confirm("Deseja sair sem gravar os dados?")) {
        return;
      }
    }
    
    next();
  },
  
  computed: {
    ...mapState(["user", "isMenuVisible"]),
    heightScroll() {
      if (this.$vuetify.breakpoint.mobile) {
        return "height: 600px;";
      } else {
        return "height: 900px;";
      }
    },
    unsavedDataExist() {
      console.log('teste inicio')
      if ((this.mode === 'save') || (this.mode === 'insert')) {
        console.log('teste true')
        return true;
      } else {
        console.log('teste false')
        return false;
      }

    }

  },
  data: function () {
    return {
      valid: false,
      rulesCNPJ: [
        (value) => !!value || "Campo obrigatório",
        (value) => (value && value.length == 14) || "Tamanho de 14 caracteres",
        (value) => (value && value.length == 14) || "Digito inválido",
        (value) =>
          (!isNaN(parseFloat(value)) &&
            value >= 0 &&
            value <= 99999999999999) ||
          "Digito inválido. Somente números",
      ],
      rulesMAC: [
        (value) => !!value || "Campo obrigatório",
        (value) => (value && value.length == 17) || "Tamanho de 17",
      ],
      rulesEmail21: this.rules,
      mode: "viewer",
      search: "",

      accessCNPJ: {},
      accessCNPJs: [],
      booleanYes: Boolean,
      headers: [
        {
          text: "Código",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "Empresa",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "CNPJ",
          align: "start",
          sortable: true,
          value: "cnpj",
        },
        {
          text: "ProcessadorID",
          align: "start",
          sortable: true,
          value: "processadorid",
        },
        {
          text: "Data Criação",
          align: "start",
          sortable: false,
          value: "createdAt",
          formatter: this.change,
        },
        {
          text: "Data Acesso",
          align: "start",
          sortable: false,
          value: "accessAt",
          formatter: this.change,
        },
        {
          text: "Data Modificação",
          align: "start",
          sortable: false,
          value: "createdAt",
          formatter: this.change,
        },
        {
          text: "Acões",
          align: "start",
          value: "action",
          sortable: false,
        },
      ],
    };
  },

  methods: {
    preventNav(event) {
      console.log("prevent") ;
      if ((this.mode === 'save') || (this.mode === 'insert')) {
        //event.preventDefault()
        event.returnValue = ""
        console.log("prevent default") ;

      } else {
        console.log("prevent default") ;
        return
      }
    },
    toTop() {
      document.getElementById("containerEdicao").scrollIntoView(0, 0);
      window.scrollTo(0, 0);
    },
    setMenuMobile() {
      if (this.$store.state.isMenuVisibleMobile) {
        this.$store.state.isMenuVisibleMobile = false;
      } else {
        this.$store.state.isMenuVisibleMobile = true;
      }
    },

    msgButton: function (value) {
      if (this.$vuetify.breakpoint.mobile) {
        return "";
      } else {
        return value;
      }
    },
    loadAccessCNPJs() {
      // window.scrollTo(0, 0);
      // this.$vuetify.goTo(0);

      const url = `${baseApiUrl}/access_cnpj`;
      axios.get(url).then((res) => {
        this.accessCNPJs = res.data;
      });
    },
    change(value) {
      // var d = new Date();
      //  var today = new Date(value - d.getTimezoneOffset() * 60000);

      var today = new Date(value);

      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var hh = String(today.getHours()).padStart(2, "0");
      var minutos = String(today.getMinutes()).padStart(2, "0");
      var ss = String(today.getSeconds()).padStart(2, "0");

      return dd + "/" + mm + "/" + yyyy + " " + hh + ":" + minutos + ":" + ss;
    },
    reset() {
      this.$refs.form.reset();
      this.accessCNPJ = {};
      this.loadAccessCNPJs();
      this.mode = "viewer";
    },
    save() {
      if (this.$refs.form.validate()) {
        const method = this.accessCNPJ.id ? "put" : "post";
        const id = this.accessCNPJ.id ? `/${this.accessCNPJ.id}` : "";
        this.accessCNPJ.userId = Number(this.user.id);

        axios[method](`${baseApiUrl}/access_cnpj${id}`, this.accessCNPJ)
          .then(() => {
            showSucess();
            this.reset();
          })
          .catch(showError);
      } else {
        this.valid = false;
      }
    },
    remove() {
      const id = this.accessCNPJ.id;
      axios
        .delete(`${baseApiUrl}/access_cnpj/${id}`)
        .then(() => {
          showSucess();
          this.reset();
        })
        .catch(showError);
    },
    // loadAccessCNPJ(accessCNPJ, mode = "save") {
    loadAccessCNPJ(accessCNPJ, mode = "save") {      
      this.mode = mode;
      this.accessCNPJ = { ...accessCNPJ };
      this.toTop();
    },
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      console.log(re.test(email));
      return re.test(email);
    },
  },
  mounted() {
    this.$store.state.isMenuVisibleMobile = true;
    this.loadAccessCNPJs();
  },
};
</script>

<style >
.texto .v-label {
  font-size: 1.3em;
}
.text-field-transparent .v-input__slot {
  background: transparent !important;
}



</style>